<template>
  <div class="sidebar pb-8 lg:pb-0">
    <div class="flex">
      <img
        v-if="content.portrait && metaContent.pageId === '21'"
        class="portrait mb-4 xl:mb-8"
        :src="content.portrait.large"
        alt="Adrian Dinner Portrait"
      >

    </div>
    <div class="flex flex-col items-start md:items-end">
      <p v-if="metaContent.pageId !== '21'" class="mb-4 md:text-right" v-html="content.berufsbezeichnung" />
      <div class="deco block mb-4 md:text-right" v-html="content.praxisLink" />
      <Go
        v-if="content.pDFAnfahrt"
        :to="content.pDFAnfahrt[0].link"
        target="_blank"
        class="address md:text-right"
        v-html="content.adresse"
      />
      <a :href="'tel:' + content.telefon" class="block mt-4 md:text-right">
        {{ content.telefon }}
      </a>
      <Go class="block md:text-right" :to="'mailto:' + content.email">
        {{ content.email }}
      </Go>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.state.router.layout.sideBarContent || {};
    },
    metaContent() {
      return this.$store.state.router.page?.meta || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  img {
    max-width: px(250);
    @screen md {
      max-width: px(300);
    }
  }
  .portrait {
    @screen lg {
      margin-left: auto;
    }
  }
  :deep(a) {
    color: var(--color-green);
    @include hover {
      text-decoration: underline;
    }
  }
  :deep(a[href^="tel"]) {
    text-decoration: none!important;
    color: black!important;
  }
}
</style>
