<template>
  <div id="nav" class="constrain py-4">
    <div class="row-12">
      <div class="relative flex justify-end md:justify-start items-center md:items-start col-3 md:col-7 xxl:col-6 order-2 md:order-1">
        <nav class="hidden md:flex md:justify-between md:w-full">
          <transition-group name="default">
            <Go
              key="trhsrth"
              class="mx-2 lg:mx-4 py-2 link first:ml-0"
              :class="{ active: $store.state.router.page.meta?.uri === '/de/' }"
              to="/de"
            >
              Home
            </Go>
            <Go
              v-for="link of navigation?.links"
              :key="link.uri"
              :to="link.uri"
              class="mx-2 lg:mx-4 py-2 link"
              :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
              v-text="link.title"
            />
          </transition-group>
        </nav>
        <Hamburger
          class="md:hidden"
          :class="{ active: mobile }"
          @click="toggle()"
        />
      </div>
      <Go
        v-if="width > 919"
        class="logo order-2 md:col-3 md:offset-2 xxl:offset-3 flex justify-start md:justify-end "
        to="/de"
      >
        <!-- <Image v-if="logoUrl" :src="logoUrl" alt="" /> -->
        <Logo />
      </Go>
      <Go v-else-if="width < 920" class="logo order-1 md:order-2 col-9 md:col-3 md:offset-2 xxl:offset-3" to="/de">
        <Logo />
        <!-- <Image v-if="logoUrl" :src="logoUrl" alt="" /> -->
      </Go>
    </div>
  </div>

  <div class="movile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav flex flex-col items-center">
        <Go
          key="trhsrth"
          class="link mb-4 py-1"
          :class="{ active: $store.state.router.page.meta?.uri === '/de/' }"
          to="/de"
          @click="toggle(true)"
        >
          Home
        </Go>
        <Go
          v-for="link of navigation?.links"
          :key="link.uri"
          :to="link.uri"
          class="link mb-4 py-1 text-center"
          :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
          @click="toggle(true)"
          v-text="link.title"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>

import Scrolllock from '@/utils/scrolllock';
// import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';
import Logo from '../../assets/images/logo.svg';

export default {
  components: {
    // Language,
    Hamburger,
    Logo,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    logoUrl() {
      return this.$store.state.router.layout?.logo;
    },
    width() {
      return window.innerWidth;
    },
    metaContent() {
      return this.$store.state.router.page?.meta || {};
    },
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  background-color: var(--color-green);
  @screen md {
    background-color: var(--color-gray);
    position: relative;
  }
}

.logo {
  font-weight: 700;
  // display: flex;
  // justify-content: flex-end;
  // align-items: flex-start;
  @screen lg {
    padding-top: 0.4rem;
  }
  @screen xl {
    padding-top: 1rem;
  }
  svg {
    color: var(--color-white);
    width: px(150);
    @screen sm {
      width: px(150);
    }
    @screen md {
      color: var(--color-green);
      width: px(300);
    }
    @screen lg {
      width: px(350);
    }
    @screen xl {
      width: px(400);
    }
  }
}

.link {
  font-size: px(18);
  font-weight: 700;
  max-width: px(280);
  color: var(--color-green);
  background-image: linear-gradient(var(--color-green), var(--color-green));
  background-size: px(0) 3px;
  background-repeat: no-repeat;
  background-position: top left;
  transition: background-size .25s ease-out;
  @screen md {
    font-size: px(16);
  }
  @screen lg {
    font-size: px(18);
  }
  &.active {
    background-size: px(40) 3px;
  }
  @include hover {
    background-size: px(40) 3px;
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    // padding: 0.8rem 1rem;
    // text-decoration: none !important;
  }
}
</style>
