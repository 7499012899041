<template>

  <transition-group name="fade" :css="transition">

    <template v-for="part of parts" :key="part.id">

      <slot
        v-if="$slots[part.template]"
        :id="part.slug"
        :name="part.template"
        :part="part"
      />

      <component
        :is="part.template"
        v-else-if="$options.components[part.template]"
        :id="part.slug"
        :payload="part"
      />

      <div v-else class="constrain">
        <strong>Unregistered Part «{{ part.template }}»!</strong>
      </div>

    </template>

  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Bildergalerie: defineAsyncComponent(() => import('@/components/parts/Bildergalerie.vue')),
    Textblock: defineAsyncComponent(() => import('@/components/parts/Textblock.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),
  },
  props: {
    parts: { type: Array, required: true },
    transition: { type: Boolean, default: true },
  },
};
</script>
