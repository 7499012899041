<template>

  <div id="overlay" />
  <div v-if="width > 919" class="green-bar bg-green md:h-8 xl:h-12 w-full mb-8" />
  <Navigation />

  <main>
    <div class="constrain pt-8 pb-12 md:pt-16 md:pb-12 xl:pt-24 xl:pb-24">
      <div class="row-12">
        <div class="order-2 md:order-1 md:col-7 xxl:col-6 mb-8 md:mb-0" :class="[ metaContent.pageId === '21' ? 'order-2' : 'order-1']">
          <Dynamic :parts="content" />
        </div>
        <div class="md:col-3 md:offset-2 xxl:offset-3" :class="[ metaContent.pageId === '21' ? 'order-1' : 'order-2']">
          <Sidebar />
        </div>
      </div>
    </div>
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Sidebar from '@/components/parts/Sidebar.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {
    Sidebar,
    Navigation,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
    metaContent() {
      return this.$store.state.router.page?.meta || {};
    },
    width() {
      return window.innerWidth;
    },
  },

};
</script>
